import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticleCard = ({ article }: any) => {
  return (
    <Link to={`/blog/${article.slug}`} className="group bg-brand-blue-light cursor-pointer">
      <GatsbyImage
        image={getImage(article.cover?.localFile)}
        alt={article.cover?.alternativeText}
        className="group-hover:scale-105 transition-transform"
      />
      <div className="p-6">
        <h2 className="text-base text-slate-500">{article.title}</h2>
        <p>{article.createdAt}</p>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    createdAt(formatString: "DD-MM-YYYY")
    cover {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.33)
        }
      }
    }
  }
`

export default ArticleCard